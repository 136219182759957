import React from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import styled from 'styled-components';
import TwoFactorForm from 'src/screens/me/pages/Security/TwoFactor/twoFactorForm';
import {hideInput2FADialog} from './redux/action';

const ModalStyled = styled(Modal)`
  >div:first-child {
    max-width: 380px;
    margin: 0 auto;
  }

  :global {
    .modal-content {
      .modal-body {
        padding: unset;
      }
    }
  }

  @media screen and (max-width: 768px) {
    :global {
      .modal-content {
        height: 100vh;
        width: 100vw;
        border-radius: unset !important;
      }
    }
  }
  
  .form {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: unset;
  }
  .welcomeTitle {
    display: none;
  }
`;

class Verify2FaModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  onChangeCode = otp => {
    const { hideInput2FADialog, callback } = this.props;
    hideInput2FADialog();
    callback(otp);
  };

  handleRequiredOTP = (require) => {
    if(!require) {
      this.props.hideInput2FADialog();
    }
  };

  render() {
    const { show, hideInput2FADialog } = this.props;
    return (
      <ModalStyled show={show} onHide={hideInput2FADialog} centered enforceFocus={false} keyboard={false}>
        <TwoFactorForm callback={this.onChangeCode} onRequireOTP={this.handleRequiredOTP} activeInput={0}/>
      </ModalStyled>
    );
  }
}

const mapState = state => ({
  ...state.twoFADialog
});
const mapDispatch = {
  hideInput2FADialog
};

export default connect(mapState, mapDispatch)(Verify2FaModal);
